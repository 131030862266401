<template>
  <div class="pound-check-wrap">
    <el-form
      v-if="ruleForm.bandList.length"
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-position="left"
      label-width="82px"
      :hide-required-asterisk="true"
      class="form-wrap"
    >
      <div
        v-for="(item, index) in ruleForm.bandList"
        :key="index"
        class="all-info"
      >
        <div class="title">
          <span>{{ typeMap[item.type]||'-' }}信息</span>
          <!-- v-if="$store.state.common.AUV_ROLE_CODE==='PT005'" -->
          <!-- v-if="['PT005'].includes($store.state.common.AUV_ROLE_CODE)" -->
          <!-- v-if="isEdit" -->
          <i v-if="isEdit" class="iconfont icon-edit-line" @click="edit(item,index)" />
        </div>
        <div class="content-wrap">
          <el-form-item
            :label="`${typeMap[item.type]}吨数：`"
            class="unit-wrap"
            :prop="'bandList.' + index + '.weightSum'"
            :rules="rules.weightSum"
          >
            <el-input
              v-if="item.showAction"
              v-model="item.weightSum"
              size="small"
              type="number"
              placeholder="请输入"
              @input="validateNumber('weightSum', 2, 3,index)"
            >
              <template v-slot:append>
                <span>吨</span>
              </template>
            </el-input>
            <span
              v-else
              class="normal-value"
            >{{ item.weightSum||'--' }}吨</span>
            <span v-if="+item.type===9" class="ml10">损耗:{{ poundCha }}吨</span>
          </el-form-item>
          <el-form-item
            :label="`${typeMap[item.type]}时间：`"
            :prop="'bandList.' + index + '.loadAndUnLoadTime'"
            :rules="rules.loadAndUnLoadTime"
          >
            <el-date-picker
              v-if="item.showAction"
              v-model="item.loadAndUnLoadTime"
              type="datetime"
              placeholder="请选择时间"
              value-format="timestamp"
              size="small"
            />
            <div v-else>{{ item.loadAndUnLoadTime | parseTime }}</div>
          </el-form-item>
          <el-form-item :label="`${typeMap[item.type]}地址：`">{{ item.address || "--" }}</el-form-item>
          <!-- {{ item.signPhoto }} -->
          <!-- item.signPhoto ? item.signPhoto.split(',').length === 1 ? [item.signPhoto] : item.signPhoto.split(',') : [] -->
          <d5c-upload
            :key-list="item.file"
            :mode="item.showAction ? 'edit' || 'preview' : 'preview'"
            :limit="1"
          />
        </div>
      </div>
    </el-form>
    <!-- <div
      v-else
      class="no-data"
    >
      暂无数据
    </div> -->
    <div v-if="showBtn" class="flex-c-c btn-wrap">
      <el-button size="mini" @click="cancel">取消</el-button>
      <el-button type="primary" size="mini" @click="submit">提交</el-button>
    </div>
  </div>
</template>

<script>
import { parseTime } from '@/utils';
import Big from 'big.js';
import poundList from '@/api/pound';
export default {
  filters: {
    parseTime,
  },
  props: {
    orderId: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    isEdit: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // 是否是千分位（值需要/1000）
    needThousands: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      typeMap: { 7: '装货', 9: '卸货' },
      ruleForm: {
        bandList: [],
        // signInfoList: [],
      },
      initDefaultData: [
        [{ type: 1, file: '', executeTime: '', address: '', createTime: '', id: '' }], // 无用 仅占位
        [{ type: 5, file: '', executeTime: '', address: '', createTime: '', id: '' }],
        [{ type: 7, weightSum: '', file: '', executeTime: '', address: '', createTime: '', id: '' }],
        [{ type: 8, file: '', executeTime: '', address: '', createTime: '', id: '' }],
        [{ type: 9, weightSum: '', file: '', executeTime: '', address: '', createTime: '', id: '' }],
      ],
      rules: {
        file: [{ required: true, validator: this.validCert, trigger: 'change' }],
        weightSum: [
          {
            required: true,
            validator: this.validateDun,
          },
        ],
        // loadAndUnLoadTime: [{ required: true, message: '请选择时间' }],
      },
      showBtn: false,
    };
  },

  computed: {
    poundCha() {
      const poundArr = [];
      if (this.ruleForm.bandList) {
        this.ruleForm.bandList.forEach(d => {
          if (!/^[0-9]+.?[0-9]*$/.test(d.weightSum)) {
            poundArr.push('NaN');
          } else {
            if (+d.type === 7) {
              poundArr.push(d.weightSum);
            } else if (+d.type === 9) {
              poundArr.push(-(d.weightSum));
            }
          }
        });
        if (!poundArr.filter(d => d === 'NaN').length) {
          return poundArr.reduce((total, value) => parseFloat(new Big(total).plus(value)), 0);
        } else {
          return '--';
        }
      } else {
        return '--';
      }
    },
  },

  created() {
    this.getCarReceipts();
  },

  methods: {
    filterArrayByType(array, type, defaultValue) {
      const filteredArray = array.filter(item => +item.nodeType === type);
      return filteredArray.length > 0 ? filteredArray : defaultValue || [];
    },
    getCarReceipts() {
      poundList.getList({ id: this.orderId })
        .then(res => {
          // 筛选2装 4卸
          const loadData = this.filterArrayByType(res.carNodeVOList, 7, this.initDefaultData[2]);
          const unloadData = this.filterArrayByType(res.carNodeVOList, 9, this.initDefaultData[4]);
          // 筛选1到装打卡 3到卸打卡
          // const loadSignInfo = this.filterArrayByType(res.carNodeVOList, 5, this.initDefaultData[1]);
          // const unloadSignInfo = this.filterArrayByType(res.carNodeVOList, 8, this.initDefaultData[3]);

          const allLoadUnloadData = loadData.concat(unloadData);
          // const allLoadUnloadSignInfo = loadSignInfo.concat(unloadSignInfo);

          const newBandList = [];
          allLoadUnloadData.forEach(e => {
            e.typeName = this.typeMap[e.nodeType || e.type];
            if (!/^[0-9]+.?[0-9]*$/.test(e.ext1)) {
              e.ext1 = '';
            } else {
              if (this.needThousands) {
                e.ext1 = parseFloat(new Big(+e.ext1).div(1000));
              } else {
                e.ext1 = +e.ext1;
              }
            }
            const obj = {
              typeName: e.typeName,
              weightSum: e.ext1,
              file: (e.files && e.files.split(',')) || [],
              isEdit: false,
              // id: e.id,parseTime
              loadAndUnLoadTime: e.nodeTime === '--' ? '' : e.nodeTime,
              type: e.nodeType || e.type,
              address: e.nodeAddr,
              nowImg: (e.files && e.files.split(',')[0]) || '',
              nowImgIndex: 0,
              showImgAction: false,
              createTime: e.nodeTime === '--' ? '' : e.nodeTime,
              id: e.cId,
              // weightSumEdit: false, // 磅单是否修改过(图片 数量 日期) 方便后期调用修改接口识别哪些磅单需要调接口 没修改到的不需要调接
              btnType: e.files ? 2 : 1, // 1是新增 2是编辑
              cAddrId: e.cAddrId || '',
              showAction: false,
            };
            newBandList.push(obj);
          });
          // 赋值装卸货磅单列表
          this.ruleForm.bandList = newBandList || [];
          // const newSignInfoList = [];
          // allLoadUnloadSignInfo.forEach(e => {
          //   // e.signtime = e.executeTime || '';
          //   const obj = {
          //     signtime: e.executeTime,
          //     signAddress: e.address,
          //     signPhoto: e.file,
          //     id: e.id,
          //   };
          //   newSignInfoList.push(obj);
          // });
          // this.ruleForm.signInfoList = newSignInfoList || [];
        });
    },
    edit(data, index) {
      this.ruleForm.bandList[index].showAction = true;
      this.showBtn = true;
    },
    cancel() {
      this.showBtn = false;
      this.$refs.ruleForm.resetFields();
      this.getCarReceipts();
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const addressTypeMap = {
            7: 1,
            9: 2,
          };
          const hasEditList = this.ruleForm.bandList.map(d => {
            return {
              id: d.cAddrId,
              cId: d.id,
              receiptNum: this.needThousands ? parseFloat(new Big(+d.weightSum).times(1000)) : +d.weightSum,
              receiptFiles: d.file.join(',') || '',
              receiptTime: d.loadAndUnLoadTime,
              type: addressTypeMap[+d.type],
            };
          });
          poundList.updatePoundBill(hasEditList)
            .then((res) => {
              if (res) {
                this.$message.success('操作成功');
                this.$emit('savePound', this.ruleForm.bandList);
                this.showBtn = false;
                this.getCarReceipts();
              }
            });
        }
      });
    },
    validCert(rule, value, callback) {
      if (!value || !(value instanceof Array) || !value.length) {
        return callback(new Error('图片不能为空'));
      }
      callback();
    },
    // 验证磅单
    validateDun(rule, value, callback) {
      const reg = /^(0|[1-9]\d*)(\s|$|\.\d{1,3}\b)/;
      const valueString = value.toString();
      const index = valueString.indexOf('.');
      let flag = false;
      const integer = valueString.slice(0, index);
      if (index >= 0 && integer.length > 6) {
        flag = true;
      } else if (index < 0 && valueString.length > 6) {
        flag = true;
      }
      if ((rule.required && !value) || (value && !reg.test(value)) || parseFloat(value) > 10000000 || flag) {
        callback(new Error('请输入合理的吨数'));
      } else {
        callback();
      }
    },
    validateNumber(val, integer, decimals, i) {
      // integer 整数位限制几位  decimals小数位限制几位
      // 正则限制输入的金额不能为中文、特殊字符、两位小数
      const reg = /^[0-9]+.?[0-9]*/;
      // 如果不符合正则，提示
      if (!reg.test(+this.ruleForm.bandList[i][val])) {
        this.ruleForm.bandList[i][val] = '';
      }
      // 保留两位小数
      const price = this.ruleForm.bandList[i][val];
      const pArr = price.split('.');
      pArr[0] = pArr[0].substr(0, integer);
      if (pArr.length > 1) {
        this.ruleForm.bandList[i][val] = pArr[0] + '.' + pArr[1].substr(0, decimals);
      } else {
        this.ruleForm.bandList[i][val] = pArr[0];
      }
      this.ruleForm.bandList[i].poundEdit = true;
    },
  },
};

</script>
<style lang='scss' scoped>
.pound-check-wrap{
  // flex-wrap: wrap;
  .form-wrap{
    display: flex;
    flex-wrap: wrap;
    .all-info{
      width: 50%;
      margin-bottom: 5px;
      .title{
        font-size: 16px;
        margin-bottom: 10px;
        span{
          color: #04122b;
          margin-right: 5px;
        }
        i{
          cursor: pointer;
        }
      }
      .content-wrap{
        padding-left: 8px;
        // line-height: 15px;
        ::v-deep{
          .el-input{
            max-width: 220px;
          }
          .el-form-item{
            height: 36px;
            margin-bottom: 5px;
            .el-form-item__content{
              display: flex;
              height: 36px;
              align-items: center;
            }
          }
        }
      }
    }
  }
  .no-data{
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-wrap{
    margin-right: 50px;
  }
}
</style>
