<template>
  <div class="content-detail-wrap flex">
    <Left v-if="!isfoldLeft" id="left-wrap" class="left" :order-information="orderInformation" :title-type="titleType" type="3" />
    <div :class="['middle card-padding', isfoldLeft ? 'middle-all' : 'middle-half']">
      <div id="scroll-wrap" ref="scrollWrap" class="scroll-wrap">
        <div class="card-title flex-c">
          <span>{{ titleType }}详情</span>
          <i class="el-icon-refresh" @click="refreshLineNum" />
        </div>
        <div class="time-line-scroll flex">
          <Timeline id="line-date" ref="timeline" :key="topComponentKey" :type="3" class="line-date" :order-id="Number(orderId)" />
          <div class="middle-top-right flexc-c-c">
            <el-button v-if="[9].includes(+orderInformation.state)&&['PT005'].includes($store.state.common.AUV_ROLE_CODE)" slot="reference" type="primary" @click="finish">磅单核定</el-button>
          </div>
        </div>
        <div :class="['transportation-num has-button', ]">
          <TotalNum :key="topComponentKey" type="3" :order-information="orderInformation" api="orderCarCountDetail" :order-id="Number(orderId)" />
        </div>
        <div class="table-wrap">
          <div class="arrow" :class="isfoldLeft ? 'arrow-left' : 'arrow1'" @click="foldLeft">
            <i :class="isfoldLeft ? 'el-icon-arrow-right' : 'el-icon-arrow-left'" />
            <span v-if="isfoldLeft">{{ titleType }}信息</span>
          </div>
          <div class="tabs-wrap">
            <Mtabs :tabs="tabs" class="tabs" :init-tab-index="activeTabIndex" @changeTab="changeTab" @refresh="refreshData" />
          </div>
          <div class="tab-down-wrap">
            <Address v-show="activeTabIndex === 0" :order-id="Number(orderId)" :address-info="addressInfo" />
            <PoundCheck v-if="activeTabIndex ===1" :key="componentKey" :order-id="Number(orderId)" :is-edit="[9].includes(+orderInformation.state)&&['PT005'].includes($store.state.common.AUV_ROLE_CODE)" @savePound="savePound" />
          </div>
        </div>
      </div>
      <transition name="fade">
        <div v-show="showBackTop" class="back-to-top" @click="backToTop">
          <i class="el-icon-top" />
        </div>
      </transition>
    </div>
    <BandDialog v-if="dialogVisible" :dialog-visible.sync="dialogVisible" :car-order-id="orderId" @savePound="savePound" />
  </div>
</template>

<script>
import vehicleBill from '@/api/transport/vehicleBill';
import Left from '@/components/OrderBusiness/left';
import Timeline from '@/components/OrderBusiness/timeline';
import TotalNum from '@/components/OrderBusiness/totalNum';
import Mtabs from '@/components/OrderBusiness/Mtabs';
import Address from '@/components/OrderBusiness/address';
import PoundCheck from './components/poundCheck';
import BandDialog from '@/components/OrderBusiness/bandDialog';

export default {
  // name: 'TransportVehiclebillDetail',
  components: { Left, Timeline, TotalNum, Mtabs, Address, PoundCheck, BandDialog },
  data() {
    return {
      titleType: '车单',
      topComponentKey: 1,
      componentKey: 1,
      orderId: '',
      orderInformation: {}, // 货单信息
      activeTabIndex: 0,
      tabs: [
        { label: '详细信息', value: 0 },
        { label: '磅单核定', value: 1 },
      ],
      isfoldLeft: false, // 左侧是否展开铺满
      showBackTop: false,
      dialogVisible: false,
      addressInfo: [], // 地址列表所需数组
    };
  },
  computed: {
  },
  created() {
    this.orderId = +this.$route.query.id;
    this.activeTabIndex = +this.$route.query.tabIndex || 0;
    this.getDetail();
  },
  mounted() {
    const box = this.$refs.scrollWrap;
    box.addEventListener(
      'scroll',
      () => {
        this.showBackTop = this.$refs.scrollWrap.scrollTop > 215;
      },
      false,
    );
  },
  methods: {
    // 货单信息+货单详情时间轴
    async getDetail() {
      const detail = await vehicleBill.orderCarOrderDetail(this.orderId).then((res) => {
        return res || {};
      });
      this.orderInformation = detail;
      if (detail.goodsAddressVOList) {
        this.addressInfo = detail.goodsAddressVOList.map(d => {
          return {
            ...d,
            earlyTime: +d.type === 1 ? detail.loadEarlyTime || '-' : detail.unloadEarlyTime || '-',
            total: 33,
          };
        });
      }
    },
    finish() {
      this.dialogVisible = true;
    },
    // 左侧展开
    foldLeft() {
      this.isfoldLeft = !this.isfoldLeft;
    },
    // 切换tab
    changeTab(data) {
      this.activeTabIndex = Number(data.value);
      // 暂无多余tab接入 暂时写法 后期提炼
      this.componentKey = this.componentKey + 1;
    },
    refreshLineNum() {
      this.topComponentKey = this.topComponentKey + 1;
      this.getDetail();
    },
    // tab下重新刷新tab数据
    refreshData() {
      this.componentKey = this.componentKey + 1;
    },
    backToTop() {
      const container = document.querySelector('#scroll-wrap');
      container.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    savePound() {
      this.getDetail();
      this.refreshLineNum();
      this.refreshData();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/goodsOrder.scss";
</style>
