<template>
  <div class="place-detail">
    <div class="main-text">地址信息</div>
    <div class="course-wrap">
      <div v-for="(item, index) in orderAddress" :key="index" class="course-list flex">
        <img v-if="item.type === 1" class="course-img" src="@/assets/images/goodsOrder/zhuang.png">
        <img v-else class="course-img" src="@/assets/images/goodsOrder/xie.png">
        <div class="course-right">
          <div class="title">
            <span class="main-text">{{ item.addrName }}</span>
          </div>
          <div class="content sub-title">
            <span>{{ item.addrRegion }}{{ item.addrDetail }}</span>
          </div>
          <!-- <div class="content">
            <span>计划{{ item.type === 1 ? "装" : "卸" }}货数量：{{ item.total||'-' }}吨</span>
          </div> -->
          <div class="content">
            <span>计划{{ item.type === 1 ? "装" : "卸" }}货时间：{{ item.earlyTime | parseTime }}</span>
          </div>
          <div class="content">
            <span>联系人：{{ item.contacter || "-" }}/{{ item.contacterPhone || "-" }}</span>
          </div>
          <!-- <div class="content">
            <span>备注：{{ item.remark }}</span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { getCarOrderAddress } from '@/api/carOrder';
import { parseTime } from '@/utils';
export default {
  filters: { parseTime },

  components: {},
  props: {
    // 订单id
    orderId: {
      type: Number,
      default() {
        return 0;
      },
    },
    // 是否需要进页面主动调取地址接口
    needActive: {
      type: Boolean,
      default() {
        return false;
      },
    },
    addressInfo: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      orderAddress: [],
      orderAddressInit: [
        {
          type: 1, // 类型:1装货地 2卸货地址
          sort: 1, // 顺序
          addrName: '-', // 地址名称
          addrRegion: '-', // 省市区
          addrDetail: '-', // 地址详情
          // unit: '', // 装卸货单位
          total: '', // 装卸货数量
          contacter: '', // 联系人
          contacterPhone: '', // 联系电话
          remark: '', // 备注
          earlyTime: '', // 计划装货时间
        },
        {
          type: 1, // 类型:1装货地 2卸货地址
          sort: 2, // 顺序
          addrName: '-', // 地址名称
          addrRegion: '-', // 省市区
          addrDetail: '-', // 地址详情
          // unit: '', // 装卸货单位
          total: '', // 装卸货数量
          contacter: '', // 联系人
          contacterPhone: '', // 联系电话
          remark: '', // 备注
          earlyTime: '', // 计划装货时间
        },
      ], // 货单地址列表
    };
  },
  watch: {
    orderId: {
      handler: function(val) {
        if (val && this.needActive) {
          this.getOrderAddress(val);
        }
      },
      immediate: true,
    },
    addressInfo: {
      handler: function(val) {
        if (val && !this.needActive) {
          this.orderAddress = this.addressInfo || this.orderAddressInit;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.orderAddress = this.orderAddressInit;
  },
  methods: {
    // 详细信息-地址信息
    getOrderAddress(id) {
      // getCarOrderAddress({ id }).then(res => {
      //   if (res.length) {
      //     this.orderAddress = res;
      //   } else {
      //     this.orderAddress = this.orderAddressInit;
      //   }
      // });
    },
  },
};
</script>
<style lang="scss" scoped>
.place-detail {
  .main-text {
    margin-bottom: 20px;
  }
  .course-wrap {
    .course-list {
      .course-left {
        position: relative;
        width: 26px;
        height: 26px;
        display: inline-block;
        vertical-align: -0.45em;
        span {
          position: absolute;
          width: 26px;
          height: 26px;
          font-size: 12px;
          text-align: center;
          line-height: 26px;
          color: #fff;
          z-index: 9;
          display: inline-block;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 13px 0 13px 13px;
            transform: rotate(135deg);
            border: none;
            z-index: -1;
          }
        }
        &.load-icon {
          margin-right: 10px;
          span {
            &::after {
              background-color: #4385ff;
            }
          }
        }
        &.unload-icon {
          margin-right: 10px;
          span {
            &::after {
              background-color: #06bd74 !important;
            }
          }
        }
      }
      .course-img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      .course-right {
        flex: 1;
        width: calc(100% - 126px);
        position: relative;
        padding-bottom: 20px;
        > div {
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        &::before {
          content: "";
          position: absolute;
          display: block;
          width: 1px;
          border-left: 1px dashed #4385ff;
          top: 26px;
          margin-left: -20.5px;
          height: calc(100% - 27px);
          background: transparent;
        }
        .title {
          > span {
            &:first-child {
              font-weight: bold;
              margin-right: 50px;
            }
          }
        }
        .content {
          > span {
            &:first-child {
              display: inline-block;
            }
          }
        }
      }
      &:last-child {
        .course-right {
          padding-bottom: 0;
          &::before {
            display: none;
          }
        }
      }
    }
  }
}
</style>
